import React, { useRef, useEffect, useState } from "react"
import { Link } from "gatsby"
import { graphql } from "gatsby"
import Layout from "./Layout"
import { Swiper, SwiperSlide } from "swiper/react"
import { EffectFade, Navigation, Autoplay } from "swiper"
import { ParallaxProvider } from "react-scroll-parallax"
import { Parallax } from "react-scroll-parallax"
import "swiper/css"
import "swiper/css/navigation"
import "swiper/css/autoplay"
import "swiper/css/effect-fade"
import { Cloudinary } from "@cloudinary/url-gen"
import { fill } from "@cloudinary/url-gen/actions/resize"
import { AdvancedImage } from "@cloudinary/react"
import { seoHead, isSafariLess16 } from "../components/utils.js"
import * as style from "./page-template-clients.module.css"

export const query = graphql`
  query ($id: ID!) {
    wpgraphql {
      page(id: $id) {
        id
        title
        content
        uri
        cfClientsParent {
          clientsParentTheTitle
          clientsParentTheClientTestimonials {
            clientsParentAClientTestim {
              ... on WPGraphQL_Page {
                title
                uri
                cfClientsSingleAll {
                  singleTestimonial
                  singleNamePosition
                  singleClientLogo {
                    altText
                    mediaDetails {
                      file
                    }
                  }
                }
              }
            }
          }
          clientsParentTheClientsFeatured {
            clientsParentAClientFeatured {
              ... on WPGraphQL_Page {
                uri
                title
                cfClientsSingleAll {
                  singleClientHeroIntro
                  clientGlobalSharedImage {
                    altText
                    mediaDetails {
                      file
                    }
                  }
                }
              }
            }
          }
          clientsParentTheClientsFinancialServices {
            clientsParentAClientFinancialServices {
              ... on WPGraphQL_Page {
                uri
                title
                cfClientsSingleAll {
                  singleClientHeroIntro
                  clientGlobalSharedImage {
                    altText
                    mediaDetails {
                      file
                    }
                  }
                }
              }
            }
          }
          clientsParentTheClientsDigitalHealth {
            clientsParentAClientDigitalHealth {
              ... on WPGraphQL_Page {
                uri
                title
                cfClientsSingleAll {
                  singleClientHeroIntro
                  clientGlobalSharedImage {
                    altText
                    mediaDetails {
                      file
                    }
                  }
                }
              }
            }
          }
          clientsParentTheClientsEnergy {
            clientsParentAClientEnergy {
              ... on WPGraphQL_Page {
                uri
                title
                cfClientsSingleAll {
                  singleClientHeroIntro
                  clientGlobalSharedImage {
                    altText
                    mediaDetails {
                      file
                    }
                  }
                }
              }
            }
          }
          clientsParentTheClientsOthers {
            clientsParentAClientOthers {
              ... on WPGraphQL_Page {
                uri
                title
                cfClientsSingleAll {
                  clientGlobalSharedImage {
                    altText
                    mediaDetails {
                      file
                    }
                  }
                }
              }
            }
          }
        }

        seo {
          title
          metaDesc
          opengraphTitle
          opengraphDescription
          opengraphUrl
          metaRobotsNoindex
          metaRobotsNofollow
          opengraphImage {
            mimeType
            mediaDetails {
              file
            }
          }
        }
      }
    }
  }
`
const PageTemplateClients = ({ data }) => {
  const page = data.wpgraphql.page
  const theClientsTestims = page.cfClientsParent.clientsParentTheClientTestimonials
  const theClientsFeatured = page.cfClientsParent.clientsParentTheClientsFeatured
  const theClientsFinancialS = page.cfClientsParent.clientsParentTheClientsFinancialServices
  const theClientsDigitalH = page.cfClientsParent.clientsParentTheClientsDigitalHealth
  const theClientsEnergy = page.cfClientsParent.clientsParentTheClientsEnergy
  const theClientsOthers = page.cfClientsParent.clientsParentTheClientsOthers


  // cloudinary
  const cld = new Cloudinary({
    cloud: {
      cloudName: process.env.GATSBY_CLOUDINARY_CLOUD,
    },
    url: {
      analytics: false,
    },
  })

  const isSafariV16 = isSafariLess16()

  return (
    <>
      <Layout>
        <div>
          <div className="darkgrey_bg mb-5 pt-5 pb-3">
            <div className="container">
              <div className="row gx-lg-5">
                <div className="col-12 col-lg-7 mt-5 color-white animated-headline align-content-center">
                  <div
                    id="hide-row-when-nav-open"
                    className="animated-headline-wrap"
                  >
                    <h1 className="lower new_style">
                      <span
                        className={`new_style_addit ${
                          isSafariV16 ? "safari_16" : ""
                        }`}
                      >
                        {page.cfClientsParent.clientsParentTheTitle}
                      </span>
                    </h1>
                  </div>
                </div>
                {/* start testim */}
                <div className="col-12 col-lg-5 mt-5 mt-lg-5 color-white header-fade-in">
                  <Swiper
                    modules={[Navigation, Autoplay, EffectFade]}
                    effect="fade"
                    fadeEffect={{ crossFade: true }}
                    autoplay={{
                      delay: 7500,
                    }}
                    speed={1500}
                    className="case_studies"
                    slidesPerView={1.2}
                    spaceBetween={20}
                    breakpoints={{
                      767: {
                        slidesPerView: 1.2,
                        spaceBetween: 20,
                      },
                      991: {
                        slidesPerView: 1,
                        spaceBetween: 30,
                      },
                    }}
                  >
                    {theClientsTestims.map((client, index) => {
                      if (
                        !client.clientsParentAClientTestim.cfClientsSingleAll
                          .singleHideTestimonial
                      ) {
                        let uClient = client.clientsParentAClientTestim
                        let testim =
                          uClient.cfClientsSingleAll.singleTestimonial
                        let testimPerson =
                          uClient.cfClientsSingleAll.singleNamePosition

                        let imgUri =
                          client.clientsParentAClientTestim.cfClientsSingleAll
                            .singleClientLogo.mediaDetails.file
                        let theAlt =
                          client.clientsParentAClientTestim.cfClientsSingleAll
                            .singleClientLogo.altText

                        let theImage = cld.image(
                          `${process.env.GATSBY_API_FOLDER_URL}${imgUri}`
                        )

                        theImage.resize(fill().width(400))
                        theImage.format("auto")

                        return (
                          <SwiperSlide
                            key={index}
                            className={`swiper-slide d-flex flex-column align-self-lg-center`}
                          >
                            <Link to={uClient.uri} title={uClient.title}>
                              <AdvancedImage
                                cldImg={theImage}
                                alt={theAlt}
                                style={{ maxWidth: "150px" }}
                              />
                              <hr />
                              <p className="mt-3 mb-2 color-white">
                                <img
                                  src="/assets/img/quotation_symbol.svg"
                                  style={{
                                    width: "25px",
                                    marginRight: "5px",
                                    top: "-5px",
                                    position: "relative",
                                  }}
                                />
                                {testim}
                              </p>
                              <hr />
                              <p>{testimPerson}</p>
                            </Link>
                            {/* <Link to={uClient.uri} title={uClient.title}>
                          <h3 className="lower">
                            {uClient.cfClientsSingleAll.singleClientHeroIntro}
                          </h3>
                        </Link> */}
                          </SwiperSlide>
                        )
                      }
                    })}
                  </Swiper>
                </div>
              </div>
            </div>
          </div>

          {/* Main */}
          <div className="container mb-5">
            <div className="row">
              <div class="col-12">
                <h3 className="lower mb-4">Featured</h3>
              </div>
              <div class="col-12">
                <div className="swiper-w-paginate">
                  <Swiper
                    modules={[Navigation, Autoplay]}
                    navigation={true}
                    autoplay={{
                      delay: 4000,
                    }}
                    speed={2500}
                    className=""
                    slidesPerView={1.2}
                    spaceBetween={20}
                    breakpoints={{
                      767: {
                        slidesPerView: 1.2,
                        spaceBetween: 20,
                      },
                      991: {
                        slidesPerView: 2.5,
                        spaceBetween: 30,
                      },
                    }}
                  >
                    {theClientsFeatured.map(client => {
                      let uClient = client.clientsParentAClientFeatured
                      let indType = client.clientsParentTheIndustry
                      //console.log(uClient.id)
                      let imgUri =
                        client.clientsParentAClientFeatured.cfClientsSingleAll
                          .clientGlobalSharedImage.mediaDetails.file
                      let theAlt =
                        client.clientsParentAClientFeatured.cfClientsSingleAll
                          .clientGlobalSharedImage.altText

                      let theImage = cld.image(
                        `${process.env.GATSBY_API_FOLDER_URL}${imgUri}`
                      )

                      theImage.resize(fill().width(1152).height(668))
                      theImage.format("auto")

                      return (
                        <>
                          <SwiperSlide className={`swiper-slide`}>
                            <div key={uClient.id}>
                              <Link to={uClient.uri} title={uClient.title}>
                                <AdvancedImage
                                  cldImg={theImage}
                                  alt={theAlt}
                                  className="w-100"
                                />
                                <p className="mt-3 mb-2 color-light-grey">
                                  {uClient.title}
                                </p>
                              </Link>

                              <Link to={uClient.uri} title={uClient.title}>
                                <h3
                                  className="lower"
                                  style={{ fontSize: "22px" }}
                                >
                                  {
                                    uClient.cfClientsSingleAll
                                      .singleClientHeroIntro
                                  }
                                </h3>
                              </Link>
                            </div>
                          </SwiperSlide>
                        </>
                      )
                    })}
                  </Swiper>
                </div>
              </div>
            </div>
          </div>

          <div className="py-5 mb-5" style={{ background: "#f5f7f9" }}>
            <div className="container">
              <div className="row">
                <div class="col-12">
                  <h3 className="lower mb-4">Financial Services</h3>
                </div>

                <div class="col-12">
                  <div className="swiper-w-paginate">
                    <Swiper
                      modules={[Navigation, Autoplay]}
                      navigation={true}
                      className=""
                      slidesPerView={1.2}
                      spaceBetween={20}
                      breakpoints={{
                        767: {
                          slidesPerView: 1.2,
                          spaceBetween: 20,
                        },
                        991: {
                          slidesPerView: 3.5,
                          spaceBetween: 30,
                        },
                      }}
                    >
                      {theClientsFinancialS.map(client => {
                        let uClient = client.clientsParentAClientFinancialServices
                        let indType = client.clientsParentTheIndustry
                        //console.log(uClient.id)
                        let imgUri =
                          client.clientsParentAClientFinancialServices.cfClientsSingleAll
                            .clientGlobalSharedImage.mediaDetails.file
                        let theAlt =
                          client.clientsParentAClientFinancialServices.cfClientsSingleAll
                            .clientGlobalSharedImage.altText

                        let theImage = cld.image(
                          `${process.env.GATSBY_API_FOLDER_URL}${imgUri}`
                        )

                        theImage.resize(fill().width(1152).height(668))
                        theImage.format("auto")

                        return (
                          <>
                            <SwiperSlide
                              // key={index}
                              className={`swiper-slide`}
                            >
                              <div key={uClient.id}>
                                <Link to={uClient.uri} title={uClient.title}>
                                  <AdvancedImage
                                    cldImg={theImage}
                                    alt={theAlt}
                                    className="w-100"
                                  />
                                  <p className="mt-3 mb-2 color-light-grey">
                                    {uClient.title}
                                  </p>
                                </Link>

                                <Link to={uClient.uri} title={uClient.title}>
                                  <h4
                                    className="lower"
                                    style={{
                                      fontSize: "20px",
                                      lineHeight: "28px",
                                    }}
                                  >
                                    {
                                      uClient.cfClientsSingleAll
                                        .singleClientHeroIntro
                                    }
                                  </h4>
                                </Link>
                              </div>
                            </SwiperSlide>
                          </>
                        )
                      })}
                    </Swiper>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className=" mb-5">
            <div className="container">
              <div className="row">
                <div class="col-12">
                  <h3 className="lower mb-4">Digital Health</h3>
                </div>
                <div class="col-12">
                  <div className="swiper-w-paginate">
                    <Swiper
                      modules={[Navigation, Autoplay]}
                      navigation={true}
                      className=""
                      slidesPerView={1.2}
                      spaceBetween={20}
                      breakpoints={{
                        767: {
                          slidesPerView: 1.2,
                          spaceBetween: 20,
                        },
                        991: {
                          slidesPerView: 4,
                          spaceBetween: 30,
                        },
                      }}
                    >
                      {theClientsDigitalH.map(client => {
                        let uClient = client.clientsParentAClientDigitalHealth
                        let indType = client.clientsParentTheIndustry
                        //console.log(uClient.id)
                        let imgUri =
                          client.clientsParentAClientDigitalHealth.cfClientsSingleAll
                            .clientGlobalSharedImage.mediaDetails.file
                        let theAlt =
                          client.clientsParentAClientDigitalHealth.cfClientsSingleAll
                            .clientGlobalSharedImage.altText

                        let theImage = cld.image(
                          `${process.env.GATSBY_API_FOLDER_URL}${imgUri}`
                        )

                        theImage.resize(fill().width(1152).height(668))
                        theImage.format("auto")

                        return (
                          <>
                            <SwiperSlide
                              // key={index}
                              className={`swiper-slide`}
                            >
                              <div key={uClient.id}>
                                <Link to={uClient.uri} title={uClient.title}>
                                  <AdvancedImage
                                    cldImg={theImage}
                                    alt={theAlt}
                                    className="w-100"
                                  />
                                  <p className="mt-3 mb-2 color-light-grey">
                                    {uClient.title}
                                  </p>
                                </Link>
                                <Link to={uClient.uri} title={uClient.title}>
                                  <h4
                                    className="lower"
                                    style={{
                                      fontSize: "20px",
                                      lineHeight: "28px",
                                    }}
                                  >
                                    {
                                      uClient.cfClientsSingleAll
                                        .singleClientHeroIntro
                                    }
                                  </h4>
                                </Link>
                              </div>
                            </SwiperSlide>
                          </>
                        )
                      })}
                    </Swiper>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="py-5" style={{ background: "#f5f7f9" }}>
            <div className="container">
              <div className="row">
                <div class="col-12">
                  <h3 className="lower mb-4">Energy</h3>
                </div>
                <div class="col-12">
                  <div className="swiper-w-paginate">
                    <Swiper
                      modules={[Navigation, Autoplay]}
                      navigation={true}
                      // autoplay={{
                      //   delay: 7500,
                      // }}
                      className=""
                      slidesPerView={1.2}
                      spaceBetween={20}
                      breakpoints={{
                        767: {
                          slidesPerView: 1.2,
                          spaceBetween: 20,
                        },
                        991: {
                          slidesPerView: 2,
                          spaceBetween: 30,
                        },
                      }}
                    >
                      {theClientsEnergy.map(client => {
                        let uClient = client.clientsParentAClientEnergy
                        let indType = client.clientsParentTheIndustry
                        //console.log(uClient.id)
                        let imgUri =
                          client.clientsParentAClientEnergy.cfClientsSingleAll
                            .clientGlobalSharedImage.mediaDetails.file
                        let theAlt =
                          client.clientsParentAClientEnergy.cfClientsSingleAll
                            .clientGlobalSharedImage.altText

                        let theImage = cld.image(
                          `${process.env.GATSBY_API_FOLDER_URL}${imgUri}`
                        )

                        theImage.resize(fill().width(1152).height(668))
                        theImage.format("auto")

                        return (
                          <>
                            <SwiperSlide
                              // key={index}
                              className={`swiper-slide`}
                            >
                              <div key={uClient.id}>
                                <Link to={uClient.uri} title={uClient.title}>
                                  <AdvancedImage
                                    cldImg={theImage}
                                    alt={theAlt}
                                    className="w-100"
                                  />
                                  <p className="mt-3 mb-2 color-light-grey">
                                    {uClient.title}
                                  </p>
                                </Link>
                                <Link to={uClient.uri} title={uClient.title}>
                                  <h4
                                    className="lower"
                                    style={{
                                      fontSize: "20px",
                                      lineHeight: "28px",
                                    }}
                                  >
                                    {
                                      uClient.cfClientsSingleAll
                                        .singleClientHeroIntro
                                    }
                                  </h4>
                                </Link>
                              </div>
                            </SwiperSlide>
                          </>
                        )
                      })}
                    </Swiper>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="py-5">
            <div className="container pt-3">
              <div className="row">
                {theClientsOthers.map(client => {
                  let uClient = client.clientsParentAClientOthers
                  let indType = client.clientsParentTheIndustry
                  //console.log(uClient.id)
                  let imgUri =
                    client.clientsParentAClientOthers.cfClientsSingleAll
                      .clientGlobalSharedImage.mediaDetails.file
                  let theAlt =
                    client.clientsParentAClientOthers.cfClientsSingleAll
                      .clientGlobalSharedImage.altText

                  let theImage = cld.image(
                    `${process.env.GATSBY_API_FOLDER_URL}${imgUri}`
                  )

                  theImage.resize(fill().width(600).height(368))
                  theImage.format("auto")

                  return (
                    <>
                      <div className="col-12 col-md-6 col-lg-4 mb-4">
                        <div key={uClient.id}>
                          <Link to={uClient.uri} title={uClient.title}>
                            <AdvancedImage
                              cldImg={theImage}
                              alt={theAlt}
                              className="w-100"
                            />
                            <p className="mt-3 mb-2 color-light-grey">
                              {uClient.title}
                            </p>
                          </Link>
                        </div>
                      </div>
                    </>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default PageTemplateClients

// Metadata in head from gatsby ^v4.19.0
// replaces helmet which wasn't generating the tags statically

export const Head = ({ data }) => {
  const page = data.wpgraphql.page
  const seoTags = seoHead(page.seo, page.uri)
  return seoTags
}
